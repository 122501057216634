import React from "react";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle'

import { Box, Heading, Text } from "@components/index";
import { ErrorWrapper, ErrorConatent, ButtonWrapper } from "./confirmation.style";
import TEXT from "@common/texts";

const ConfirmationSection = ({ imageWrapper, titleStyle, textStyle}) => {
  return (
    <ErrorWrapper>
      <ErrorConatent>
        <Box {...imageWrapper} className="image-wrapper">
          <Icon icon={ic_check_circle} size={200} style={{ alignContent: 'center', alignSelf: 'center', justifyContent: 'center', color: 'green'}} />
        </Box>
        <Heading {...titleStyle} content={TEXT.APPLICATION_SUBMITED_SUCCESS} />
        <Text {...textStyle} content={TEXT.APPLICATION_SUBMITED_SUCCESS_MESSAGE} />
        <ButtonWrapper>

        </ButtonWrapper>
      </ErrorConatent>
    </ErrorWrapper>
  );
};

ConfirmationSection.propTypes = {
  imageWrapper: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  reloadButtonStyle: PropTypes.object,
  homeButtonStyle: PropTypes.object
};

ConfirmationSection.defaultProps = {
  imageWrapper: {
    mb: ["40px", "55px"],
  },
  titleStyle: {
    fontSize: ["26px", "32px", "38px", "48px"],
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px", "25px", "25px", "35px"],
    lineHeight: "1.31",
    textAlign: "center",
    fontFamily: "poppins",
    fontWeight: "600"
  },
  textStyle: {
    fontSize: ["18px", "18px", "18px", "18px", "18px"],
    fontWeight: "400",
    color: "#0f2137",
    lineHeight: "1.75",
    mb: "8",
    paddingRight: "8",
    paddingLeft: "8"
  },
  reloadButtonStyle: {
    type: "button",
    fontSize: "16px",
    fontWeight: "500",
    color: "#fff",
    pl: ["15px", "22px"],
    pr: ["15px", "22px"],
    iconPosition: "left",
    bg: "#eaa03b",
    fontFamily: "lato"
  },
  homeButtonStyle: {
    type: "button",
    fontSize: "16px",
    fontWeight: "500",
    color: "#0f2137",
    pl: ["15px", "22px"],
    pr: ["15px", "22px"],
    iconPosition: "left",
    bg: "#e2e7f0",
    fontFamily: "lato"
  }
};

export default ConfirmationSection;
